
import { defineComponent, onMounted, nextTick, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { showModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";
import ClaimUploadSettlementReceipt from "@/components/modals/ClaimUploadSettlementReceipt.vue";

export default defineComponent({
  name: "underwriter-pending-claim-payments",
  components: { ClaimUploadSettlementReceipt },
  props: {
    redirect: String,
    policySummary: Object,
  },
  setup() {
    const {
      truncate,
      reinitialization,
      isEmptyArray,
      displayDatatable,
      getSettlementOfferStatus,
    } = ResusableFunctions();

    const isUploadPaymentModalVisible = ref(false);
    const details = ref({
      publicId: "",
      type: "",
    });

    const payments = ref([
      {
        claimNumber: "",
        estimatedAmount: "",
        insuredName: "",
        offeredAmount: "",
        publicId: "",
        type: "",
        status: "",
      },
    ]);

    // Function to get pending settlment payments
    const getPayments = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.UNDERWRITER_ROUTE}/e-dv`)
          .then(({ data }) => {
            //Assign data to form fields
            payments.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    const uploadPaymentReceiptModal = (publicId: string, type: string) => {
      details.value = {
        publicId,
        type,
      };

      setTimeout(() => {
        nextTick(() => {
          const modal = document.getElementById(
            "claim_upload_settlement_receipt_modal"
          );
          if (modal) {
            showModal(modal);
          }
        });
      }, 100);

      isUploadPaymentModalVisible.value = true;
    };

    // Call the getPayments function when the component is mounted
    onMounted(() => {
      setCurrentPageBreadcrumbs("Pending Payments", ["Clients", "Claims"]);

      getPayments();

      if (!isEmptyArray(payments.value)) {
        displayDatatable(["basicExample"]);
      }
    });

    return {
      truncate,
      reinitialization,
      addCommaToNumberString,
      getPayments,
      uploadPaymentReceiptModal,
      getSettlementOfferStatus,
      payments,
      isUploadPaymentModalVisible,
      details,
    };
  },
  created() {
    //Set page title
    document.title =
      "Underwriter: Pending Payments | " + process.env.VUE_APP_TITLE;
  },
});
