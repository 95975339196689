
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "claim-e-discharge-voucher-modal-component",
  components: { ErrorMessage, Field, Form },
  props: {
    publicId: String,
    type: String,
  },
  setup(props, { emit }) {
    const submitButton1 = ref<null | HTMLButtonElement>(null);
    const evidenceOfPayment = ref("");

    const uploadEDVValidator = Yup.object().shape({
      evidenceOfPayment: Yup.string()
        .required()
        .label("Settlement receipt is required"),
    });

    const selectDocument = (event) => {
      evidenceOfPayment.value = event.target.files[0];
    };

    const submitForm = (_, { resetForm }) => {
      if (submitButton1.value) {
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
          const formData = new FormData();
          formData.append("evidenceOfPayment", evidenceOfPayment.value);

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              `${variables.UNDERWRITER_ROUTE}${variables.SETTLEMENT_ROUTE}/${props?.publicId}/payment-evidence`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  `Successfully submitted receipt for ${props?.type}`,
                  "success"
                );
                resetForm();
                hideModal(
                  document.getElementById(
                    "claim_upload_settlement_receipt_modal"
                  )
                );
                emit("getPendingPayments");
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      uploadEDVValidator,
      submitForm,
      submitButton1,
      selectDocument,
      evidenceOfPayment,
    };
  },
});
